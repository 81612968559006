@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    src: url('https://cdn.chefsimon.com/fonts/poppins-v21-latin-regular.woff2') format('woff2'),
    url('https://cdn.chefsimon.com/fonts/poppins-v21-latin-regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    src: url('https://cdn.chefsimon.com/fonts/poppins-v21-latin-500.woff2') format('woff2'),
    url('https://cdn.chefsimon.com/fonts/poppins-v21-latin-500.ttf') format('truetype');
}

@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    src: url('https://cdn.chefsimon.com/fonts/poppins-v21-latin-600.woff2') format('woff2'),
    url('https://cdn.chefsimon.com/fonts/poppins-v21-latin-600.ttf') format('truetype');
}

@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 800;
    src: url('https://cdn.chefsimon.com/fonts/poppins-v21-latin-800.woff2') format('woff2'),
    url('https://cdn.chefsimon.com/fonts/poppins-v21-latin-800.ttf') format('truetype');
}