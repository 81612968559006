@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
.heart-animate {
  animation: pulse 0.5s ease;
}

/* Permettre au SVG de déborder */
svg.heart {
  overflow: visible;
}