

@layer base {
  h1 {
    @apply text-3xl lg:text-5xl lg:leading-tight my-3 md:my-5 font-bold antialiased ;
  }

  h2 {
    @apply text-2xl lg:text-3xl mt-7 mb-5 font-bold text-black-header-2 antialiased;
  }

  h3 {
    @apply text-base lg:text-lg font-medium mb-3;
  }

  h5 {
    @apply font-semibold text-lg;
  }

  p {
    @apply text-sm text-gray-main leading-normal mb-5 link_cs ;
  }

  ul {
    @apply text-sm text-gray-main leading-normal mb-5 ;
  }

  b, strong {
    @apply font-semibold;
  }

  .link_cs a {
    @apply text-red-500 outline-none;
  }


  .link_cs a:hover {
    @apply underline;
  }

  .other-links {
    @apply mt-5;
  }

  .other-links > a:after {
    content: " >> ";
  }

  .red_button {
    @apply whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border-2 border-red shadow-sm text-sm font-medium text-white bg-red-500 hover:bg-red-600 hover:border-red-600 cursor-pointer outline-none focus:outline-none;
  }


  .white_button {
    @apply whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border bg-white border-gray-200 shadow-sm text-xs font-normal hover:bg-red hover:border-red hover:text-white cursor-pointer outline-none focus:outline-none;
  }


  .input_form {
    @apply bg-gray-100 text-sm text-black-400 border border-gray-200 hover:border-gray-400 focus:border-gray-400 focus:ring focus:ring-black-200 focus:ring-opacity-20 placeholder-black-200;
  }

  .input_form_white {
    @apply bg-white text-sm text-black-400 border border-gray-200 hover:border-gray-400 focus:border-gray-400 focus:ring focus:ring-black-200 focus:ring-opacity-20 placeholder-black-200;
  }

  table {
    @apply text-sm text-black-200 border border-gray-200 link_cs table-auto mt-4
  }

  tr, td, th {
    @apply border border-gray-200
  }

  td, th {
    @apply p-1.5
  }

  tr { @apply hover:bg-gray-100 }
  th { @apply bg-gray-200 font-medium  }

  .header h5 {
    @apply text-black-header mb-4
  }


}


.tooltip {
  @apply invisible absolute;
}

.has-tooltip:hover .tooltip {
  @apply visible z-50;
}


.pagination {
  @apply flex justify-center;
  @apply my-2 py-2 text-sm font-medium;
}

.pagination * {
  @apply p-1 md:p-2 mx-auto;
  @apply no-underline;
}

.pagination a:hover {
  @apply text-white bg-red;
}

.pagination .current {
  @apply text-white bg-red font-medium not-italic;
}