


.select_file_default_image {
  @apply top-0 right-0 w-52 h-52;
}



.select_file_toolbar {
  @apply bottom-1 left-1 w-9 pl-2 pt-2 ;
}
