// Simulate Bootstrap embed responsive video
// Inspired by https://github.com/drdogbot7/tailwindcss-responsive-embed
.embed-responsive {
  position: relative;
  display: block;
  height: 0;
  padding: 0;
  overflow: hidden;

  .embed-responsive-item,
  iframe,
  embed,
  object,
  video {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    border: 0;
  }
}

.embed-responsive-16by9 {
  padding-bottom: 56.25%;
}


.markdown-body {

  img {
    @apply float-left align-text-top mb-3 mr-3 rounded;
  }

  p { @apply clear-both; }

  ul, ol {
    @apply mb-7 list-inside;
    li {
      @apply text-sm text-black-200 mb-3;
      p { display: inline; }
    }
  }

  ul { @apply list-disc ml-9 link_cs}
  ol { @apply list-decimal link_cs}

}

