


.sharing-toolbar-button {
  @apply border p-1 min-w-min bg-white border-black-header-2;
}

.sharing-toolbar-button:hover {
  color: white;
  .sharing-toolbar-button-icon { color:white; }
}


.sharing-toolbar-button-icon svg {
  @apply w-5 h-5;
}



/* Solid icons get a fill */
.sharing-toolbar-button-icon {
  @apply fill-current text-black-header-2;
}


.sharing-toolbar-x-ex-twitter:hover {
  background-color: #2795e9;
}


.sharing-toolbar-pinterest:hover {
  background-color: #bd081c;
}


.sharing-toolbar-facebook:hover {
  background-color: #3b5998;
}

.sharing-toolbar-print:hover {
  background-color: #5e5e5e;
}